<template>
    <div @click="click">
        
    </div>
</template>

<script>
export default {
    data(){
        return {
            
        }
    },
    methods: {
        click(){
            console.log('click');
            this.request().then(res => {
                navigator.clipboard.writeText(res).then(res => {
                    console.log(res);
                })

            })

        },
        request(){
            return new Promise((resolve,reject) => {
                setTimeout(() => {
                    resolve(document.getSelection().toString());
                },1000)
            })
        }
    },
    mounted(){
        
    },
}
</script>

<style lang="less" scoped>

</style>